import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';

import Typography from 'components/uiLibrary/Typography';
import Image from 'components/uiLibrary/Image';
import Drawer from 'components/uiLibrary/Drawer';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Testimonials from 'components/Globals/Testimonials';
import CustomerLogos from 'components/Globals/CustomerLogos';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import LinkButton, { PrimaryButton } from 'components/uiLibrary/LinkButton';
import CommonTrans from 'components/Globals/CommonTrans';
import { COMPONENTS, SECTIONS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import queries from 'containers/Globals/queries';

import useAppContext from 'utils/hooks/useAppContext';
import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useUserProfiles, useUserData, useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import useClaimAccess from 'utils/hooks/useClaimAccess';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import { useTranslation } from 'src/i18n';

import {
  TP,
  BASE_PAGE_ROUTES,
  ARTIST_TYPE_ID,
  ENTITY_TYPE,
  PROFILE_TYPES,
  POPUP_TYPES,
  POPUP_SOURCES,
  PAYWALL_TYPES,
} from 'constants/index';

import LogoImage from 'public/images/logo.png';

// eslint-disable-next-line import/no-cycle
import { TYPE } from '../ConversionFlowBanners';

import classes from './BannerDetailsDrawer.module.scss';

const staticInfoKeys = {
  performances: `${TP}.m_PERF`,
  companies: `${TP}.m_OPCOS`,
  festivals: `${TP}.m_FESTIVALS`,
  artists: `${TP}.m_ARTISTS`,
  managers: `${TP}.m_MANAGERS`,
};

const ARTIST_FEATURES = ['ARTIST_BANNER_FEATURE_1', 'ARTIST_BANNER_FEATURE_2', 'ARTIST_BANNER_FEATURE_3'];

const CASTING_FEATURES = [
  'CASTING_BANNER_FEATURE_1',
  'CASTING_BANNER_FEATURE_2',
  'CASTING_BANNER_FEATURE_3',
  'CASTING_BANNER_FEATURE_4',
];

const ArtistFeatures = ({ isDesktop }) => {
  const { t } = useTranslation('NS_CONVERSION_FLOW');

  return (
    <div className={classes.bannerDrawer__artistFeatures}>
      {ARTIST_FEATURES.map((feature, index) => (
        <div key={index} className={classes.bannerDrawer__artistFeatureItem}>
          <SpriteIcon icon="task_alt" size={16} className={classes.bannerDrawer__featureIcon} />
          <Typography size={isDesktop ? 14 : 12} color="secondary">
            <CommonTrans
              i18nKey={t(feature)}
              ns="NS_CONVERSION_FLOW"
              components={{
                b: <b />,
              }}
            />
          </Typography>
        </div>
      ))}
    </div>
  );
};

const CastingFeatures = ({ isDesktop }) => {
  const { t } = useTranslation('NS_CONVERSION_FLOW');

  return (
    <div className={classes.bannerDrawer__castingFeatures}>
      {CASTING_FEATURES.map((feature, index) => (
        <div key={index} className={classes.bannerDrawer__castingFeatureItem}>
          <SpriteIcon icon="task_alt" size={16} className={classes.bannerDrawer__featureIcon} />
          <Typography size={isDesktop ? 14 : 12} color="secondary">
            {t(feature)}
          </Typography>
        </div>
      ))}
    </div>
  );
};

const DesktopContent = ({ isArtist, onClick, onClose, subComponent, staticInfo, isDesktop, buttonCTAText }) => {
  const { t } = useTranslation('NS_CONVERSION_FLOW');

  return (
    <div className={classnames(classes.bannerDrawer, { [classes.bannerDrawer__artist]: isArtist })}>
      <div className={classes.bannerDrawer__left}>
        <div className={classes.bannerDrawer__leftTitle}>
          <Typography variant="h4" size="18">
            {isArtist ? t('NUDGE_TITLE_MAIN') : 'Operabase'}
          </Typography>
          <Typography variant="h4" size="24" weight="bold" secondaryFont>
            {isArtist ? t('ARTISTS_AND_MANAGERS') : t(`${TP}.FN_CASTINGTOOL`)}
          </Typography>
        </div>
        <Image
          src={
            isArtist
              ? 'https://public.operabase.com/images/conversionFlow/artist_devices_20240620T105101798Z.webp'
              : 'https://public.operabase.com/images/conversionFlow/casting_devices_20240620T105114368Z.webp'
          }
          alt="Operabase Devices"
          title="Operabase Devices"
          lazy={false}
          width={500}
          height={310}
          disableCloudinary
          disableNextImage
        />
      </div>
      <div className={classes.bannerDrawer__right}>
        <div className={classes.bannerDrawer__header}>
          <div>
            <div className={classes.bannerDrawer__logo}>
              <Image src={LogoImage} alt="Operabase Home" height={14} width={140} />
              <Typography size="11" color="secondary" className={classes.bannerDrawer__logoSubText}>
                {t(`${TP}.LP_SINCE`)}
              </Typography>
            </div>
            <Typography size="10" color="secondary">
              <CommonTrans
                i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
                ns="NS_CONVERSION_FLOW"
                components={{
                  ul: <ul className={classes.bannerDrawer__listWorks} />,
                  li: <li />,
                }}
              />
            </Typography>
          </div>
          <LinkButton
            variant="text"
            leftIcon={<SpriteIcon icon="modal_close" size={16} />}
            onClick={onClose}
            disableHover
            disableUnderline
          >
            <Typography size="12" weight="medium">
              {t(`${TP}.FN_CLOSE`)}
            </Typography>
          </LinkButton>
        </div>
        <div className={classes.bannerDrawer__main}>
          <div className={classes.bannerDrawer__mainTop}>
            <Typography size="28" weight="bold">
              {isArtist ? t('ARTIST_BANNER_DRAWER_TITLE') : t('CASTING_BANNER_DRAWER_TITLE')}
            </Typography>
            {isArtist ? <ArtistFeatures isDesktop={isDesktop} /> : <CastingFeatures isDesktop={isDesktop} />}
          </div>
          <div className={classes.bannerDrawer__mainBottom}>
            <ul className={classes.stats}>
              {staticInfo.map((item, index) => (
                <li key={index} className={classes.stats__item}>
                  <Typography size="13" weight="bold" className={classes.stats__number}>
                    {item?.total}
                  </Typography>
                  <Typography size="13" color="secondary">
                    {t(item?.title)}
                  </Typography>
                </li>
              ))}
            </ul>
            <CustomerLogos className={classes.trustedByBlock} rowHeight={40} showTitle />
            <div>
              <HorizontalScrollIndicators
                styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
                triggerSize={0}
                isArrowsEnabled
              >
                <Testimonials hideTitle inLine limit={10} className={classes.testimonials__list} />
              </HorizontalScrollIndicators>
            </div>
          </div>
        </div>
        <div className={classes.bannerDrawer__footer}>
          <PrimaryButton
            styles={{ root: classes.bannerDrawer__footerBtn }}
            rightIcon={<SpriteIcon icon="arrow_forward" size="16" />}
            onClick={onClick}
            trackingData={{
              section: SECTIONS.HERO_BANNER_DRAWER,
              component: COMPONENTS.ADVERTISEMENT,
              subComponent,
            }}
            preventDefault
            stopPropagation
          >
            {t(buttonCTAText)}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

const MobileContent = ({
  isArtist,
  onClick,
  onClose,
  subComponent,
  staticInfo,
  isDesktop,
  isMobile,
  buttonCTAText,
}) => {
  const { t } = useTranslation('NS_CONVERSION_FLOW');

  return (
    <div className={classes.bannerMobileDrawer}>
      <div className={classes.bannerMobileDrawer__header}>
        <div className={classes.bannerMobileDrawer__headerContent}>
          <div className={classes.bannerDrawer__logo}>
            <Image src={LogoImage} alt="Operabase Home" height={14} width={140} />
            <Typography size="11" color="secondary" className={classes.bannerDrawer__logoSubText}>
              {t(`${TP}.LP_SINCE`)}
            </Typography>
          </div>
          <Typography size="10" color="secondary">
            <CommonTrans
              i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
              ns="NS_CONVERSION_FLOW"
              components={{
                ul: <ul className={classes.bannerDrawer__listWorks} />,
                li: <li />,
              }}
            />
          </Typography>
        </div>
        <LinkButton
          styles={{ root: classes.bannerMobileDrawer__close }}
          variant="text"
          leftIcon={<SpriteIcon icon="close" size={16} />}
          onClick={onClose}
        />
      </div>
      <div className={classes.bannerMobileDrawer__main}>
        <Typography variant="h4" size="12" align="center">
          {isArtist ? t('NUDGE_TITLE_MAIN') : 'Operabase'}
        </Typography>
        <Typography variant="h4" size="20" weight="bold" secondaryFont align="center">
          {isArtist ? t('ARTISTS_AND_MANAGERS') : t(`${TP}.FN_CASTINGTOOL`)}
        </Typography>
        <Image
          src={
            isArtist
              ? 'https://public.operabase.com/images/conversionFlow/artist_mobile_devices_20240724T161719986Z.webp'
              : 'https://public.operabase.com/images/conversionFlow/casting_mobile_devices_20240724T161719986Z.webp'
          }
          alt="Operabase Devices"
          title="Operabase Devices"
          lazy={false}
          width={isMobile ? 262 : 375}
          height={isMobile ? 140 : 200}
          className={classes.bannerMobileDrawer__image}
          disableCloudinary
          disableNextImage
        />
        <div
          className={classnames(classes.bannerMobileDrawer__features, {
            [classes.bannerMobileDrawer__featuresArtist]: isArtist,
          })}
        >
          <Typography size="16" weight="bold" align="center">
            {isArtist ? t('ARTIST_BANNER_DRAWER_TITLE') : t('CASTING_BANNER_DRAWER_TITLE')}
          </Typography>
          {isArtist ? <ArtistFeatures isDesktop={isDesktop} /> : <CastingFeatures isDesktop={isDesktop} />}
        </div>
      </div>
      <div className={classes.bannerDrawer__footer}>
        <PrimaryButton
          styles={{ root: classes.bannerDrawer__footerBtn }}
          rightIcon={<SpriteIcon icon="arrow_forward" size="16" />}
          onClick={onClick}
          trackingData={{
            section: SECTIONS.HERO_BANNER_DRAWER,
            component: COMPONENTS.ADVERTISEMENT,
            subComponent,
          }}
        >
          {t(buttonCTAText)}
        </PrimaryButton>
      </div>
      {!isArtist && (
        <ul className={classes.stats}>
          {staticInfo.map((item, index) => (
            <li key={index} className={classes.stats__item}>
              <Typography size="13" weight="bold" className={classes.stats__number}>
                {item?.total}
              </Typography>
              <Typography size="13" color="secondary">
                {t(item?.title)}
              </Typography>
            </li>
          ))}
        </ul>
      )}
      <div className={classes.bannerMobileDrawer__footer}>
        <div className={classes.bannerMobileDrawer__customerLogo}>
          <Typography size="11" italic weight="medium">
            {t(`${TP}.FN_LOGIN_POPUP_BOTTOM_SECTION_TITLE`)}
          </Typography>
          <CustomerLogos count="4" rowHeight="35" className={classes.trustedByStrip} allowShowAll />
          <div className={classes.testimonialContainer}>
            <Testimonials limit={5} hideTitle />
          </div>
        </div>
      </div>
    </div>
  );
};

const BannerDetailsDrawer = ({ isOpen, onClose, type }) => {
  const { isDesktop, isMobile } = useDeviceTypeLayouts();
  const { navigate, permissions } = usePageContext();
  const { isLoggedIn, castingToolPermissions } = permissions;
  const { setIsLoginDialog } = useDialogs();
  const { setPaywallType } = useAppContext();
  const profiles = useUserProfiles();
  const { profileData, entityType } = useGetTargetProfile();
  const { ownerProfile } = useProfileAccesses();
  const user = useUserData();
  const { setSubscriptionNudgesPopup } = useNotificationPopup();
  const { data } = useQuery(queries.getHomeNumbers());
  const { claimNewProfileHandler } = useClaimAccess();
  const isArtist = type === TYPE.ARTIST;
  const [buttonCTAText, subComponent] = (() => {
    if (!isLoggedIn) {
      return ['LOGIN_OR_REGISTER', SUB_COMPONENTS.LOGIN_OR_REGISTER_CTA];
    }
    if (isArtist) {
      return user?.trialExhausted
        ? ['CL_UPGRADE_TO_PREMIUM', SUB_COMPONENTS.UPGRADE_TO_ARTIST_PREMIUM_CTA]
        : ['CLAIM_YOUR_FREE_MONTH_NOW', SUB_COMPONENTS.CLAIM_YOUR_FREE_MONTH_CTA];
    }
    return [`${TP}.FN_CONVERSION_TRY_NOW`, SUB_COMPONENTS.TRY_NOW_CTA];
  })();

  const staticInfo = useMemo(
    () =>
      Object.entries(staticInfoKeys).map(([key, title]) => ({
        total: data?.data?.[key]?.total,
        title,
      })),
    [data?.data],
  );

  const handleCheckPermissionPopup = useCallback(() => {
    if (!isLoggedIn) {
      setIsLoginDialog({ isOpen: true });
      return;
    }

    const navigateToRoute = (baseRoute, queryParams = {}) => {
      const linkProps = navigate.getLinkProps({
        baseRoute,
        queryParams,
        onlyLinkProps: true,
      });
      navigate.to(linkProps);
    };

    const handleCastingToolAccess = () => {
      if (castingToolPermissions?.hasAccess) {
        navigateToRoute(BASE_PAGE_ROUTES.CASTING);
      } else if (profiles?.length) {
        setPaywallType(PAYWALL_TYPES.CASTING_TOOL);
      } else {
        claimNewProfileHandler();
      }
    };

    const handleProfileAccess = () => {
      if (profiles?.length && entityType === ENTITY_TYPE.PROFILE) {
        const profile = profiles.find(p => p.id === profileData?.id || ownerProfile?.profile?.id);
        const isChurned = profile?.subscriptionStatus === PROFILE_TYPES.CHURNED;
        if (isChurned) {
          setSubscriptionNudgesPopup({
            isOpen: true,
            popupType: isChurned ? POPUP_TYPES.CHURNED : POPUP_TYPES.UPGRADE,
            source: POPUP_SOURCES.DEFAULT_TRIAL,
          });
          return;
        }

        if (profile.isPro) {
          const linkProps = navigate.getLinkProps({
            entityType: ENTITY_TYPE.ARTIST,
            entity: profileData?.id ? profileData : ownerProfile?.profile,
            onlyLinkProps: true,
            edit: true,
          });
          navigate.to(linkProps);
        } else {
          setPaywallType(PAYWALL_TYPES.CASTING_TOOL);
        }
      } else {
        navigateToRoute(BASE_PAGE_ROUTES.REGISTER, { as: ARTIST_TYPE_ID });
      }
    };

    if (type === TYPE.CASTING) {
      handleCastingToolAccess();
    } else {
      handleProfileAccess();
    }
  }, [
    isLoggedIn,
    type,
    setIsLoginDialog,
    navigate,
    castingToolPermissions?.hasAccess,
    profiles,
    setPaywallType,
    claimNewProfileHandler,
    entityType,
    profileData,
    ownerProfile?.profile,
    setSubscriptionNudgesPopup,
  ]);

  const handleCTAClick = () => {
    handleCheckPermissionPopup();
    onClose();
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      className={isArtist ? classes.drawerRootArtist : classes.drawerRootCasting}
    >
      {isDesktop ? (
        <DesktopContent
          isArtist={isArtist}
          onClick={handleCTAClick}
          subComponent={subComponent}
          onClose={onClose}
          staticInfo={staticInfo}
          buttonCTAText={buttonCTAText}
        />
      ) : (
        <MobileContent
          isArtist={isArtist}
          onClick={handleCTAClick}
          subComponent={subComponent}
          onClose={onClose}
          staticInfo={staticInfo}
          isMobile={isMobile}
          buttonCTAText={buttonCTAText}
        />
      )}
    </Drawer>
  );
};

export default BannerDetailsDrawer;
